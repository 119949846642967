import React, { Component, useState, useEffect } from 'react';
import { Trans, withI18n } from '@lingui/react';

// images
import kickStarterGreen from './img/kickstarter-banner/kickstarter-green.png';
import lifecycleLogo from './img/kickstarter-banner/lifecycle-logo.png';
import justLauched from './img/kickstarter-banner/just-launched.png';
import closeIcon from './img/kickstarter-banner/close-icon.png';

import Button from '../components/Button';
import { useCookies } from "react-cookie";

function KickStarterBanner(props) {
  const [showBanner, setBannerDisplay] = useState(true)
  const [cookies, setCookie, getCookie] = useCookies(['ii_kickstarter']);

  useEffect(() => {
    if (cookies['ii_kickstarter']) {
      setBannerDisplay(false);
      props.onClose();
    }
  }, [])

  const close = () => {
    setBannerDisplay(false);
    // setCookie()
    const expiration = new Date();
    expiration.setDate(expiration.getDate()+9999);
    setCookie("ii_kickstarter", true, {path: '/', expires: expiration});

    props.onClose();
  }

  return (
    <div className={`kick-starter-banner ${!showBanner ? 'hide' : ''}`}>
      { <div className="container">
        <img className="lifecycle-logo" src={lifecycleLogo} alt='' />
        <img className="just-launced" src={justLauched} alt="" />
        <Button className="button" target="_blank" href="https://www.kickstarter.com/projects/interstellar-games/lifecycle-champions-arena">Back us on <img src={kickStarterGreen} alt="" /></Button>
      </div>}
      <button className="close" onClick={close}><img src={closeIcon} alt="" /> </button>
    </div>
  )
}

export default withI18n()(KickStarterBanner);
