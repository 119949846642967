import React from "react";

const SvgComponent = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g id="Page-1" fill="none" fillRule="evenodd">
      <g id="Artboard-1" transform="translate(-90 -96)" fill="#FFF">
        <path
          d="M102.005 101.893a6.118 6.118 0 0 0-6.128 6.128 6.118 6.118 0 0 0 6.128 6.128 6.118 6.118 0 0 0 6.128-6.128 6.118 6.118 0 0 0-6.128-6.128zm0 10.112a3.991 3.991 0 0 1-3.984-3.984 3.988 3.988 0 0 1 3.984-3.984 3.988 3.988 0 0 1 3.984 3.984 3.991 3.991 0 0 1-3.984 3.984zm7.808-10.362c0 .794-.64 1.429-1.429 1.429a1.43 1.43 0 1 1 1.43-1.43zm4.059 1.45c-.09-1.914-.528-3.61-1.93-5.008-1.398-1.397-3.094-1.834-5.009-1.93-1.973-.112-7.888-.112-9.861 0-1.91.09-3.605.528-5.008 1.925s-1.835 3.093-1.93 5.008c-.113 1.973-.113 7.888 0 9.861.09 1.915.527 3.611 1.93 5.008 1.403 1.398 3.093 1.835 5.008 1.931 1.973.112 7.888.112 9.861 0 1.915-.09 3.611-.528 5.008-1.93 1.398-1.398 1.835-3.094 1.931-5.009.112-1.973.112-7.882 0-9.856zm-2.55 11.974a4.033 4.033 0 0 1-2.271 2.272c-1.574.624-5.307.48-7.046.48-1.738 0-5.477.138-7.045-.48a4.033 4.033 0 0 1-2.272-2.272c-.624-1.574-.48-5.307-.48-7.046 0-1.738-.139-5.477.48-7.045a4.033 4.033 0 0 1 2.272-2.272c1.573-.624 5.307-.48 7.045-.48 1.739 0 5.478-.139 7.046.48a4.033 4.033 0 0 1 2.272 2.272c.624 1.573.48 5.307.48 7.045 0 1.739.144 5.478-.48 7.046z"
          id="mask"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;

