import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Trans, withI18n } from '@lingui/react';
import { prefix } from '../i18n-config';
// import Img from 'gatsby-image';
import Slider from "react-slick";

// images
import menuScreenUrl from './img/menu-screen.svg';

import Button from './Button';

// images
import LogoInterStellar from './img/LogoInterStellar';
import LifeCycleLogo from '../pages/img/life-cycle/logo.png';
import RobloxLogo from '../pages/img/roblox/logo.png';
import ComingSoonLogo from '../pages/img/coming-soon/logo.png';
import Widowmaker from '../pages/img/life-cycle/widowmakerV2.png';
import Baptiste from '../pages/img/life-cycle/baptisteV2.png';
import Genji from '../pages/img/coming-soon/genji.png';
import Character from '../pages/img/roblox/character.png';


class NavPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liHover: '',
      hasScrolled: props.onComingSoon ? true : false,
      scrollY: 0,
      onComingSoon: props.onComingSoon || false
    };
    this.Pos = '';
    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const { hasScrolled, liHover } = this.state;
    window.addEventListener('scroll', this.handleScroll);
    setInterval(() => {
      if (hasScrolled) {
        this.fixNavOnScroll();
      }
    }, 150);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleToggleClick(e) {
    this.handleEnter(e);
  }

  handleEnter(e) {
    const target = e.target.dataset.target;

    this.setState({
      liHover: target,
    });
  }

  handleLeave() {
    this.setState({
      liHover: '',
    });
  }

  handleScroll() {
    this.setState({
      hasScrolled: true,
    });
  }

  fixNavOnScroll() {
    const { scrollY } = this.state;
    const currentPos = window.scrollY;
    if (Math.abs(scrollY - currentPos) <= 20) {
      return;
    }
    if (scrollY > 0 && currentPos > scrollY) {
      // Scroll Down
      this.setState({ navUp: true });
    } else {
      // Scroll Up
      this.setState({ navUp: false });
    }
    if (currentPos > 150) {
      this.setState({ headerBg: true });
    } else {
      this.setState({ headerBg: false });
    }
    this.setState({
      hasScrolled: false,
      scrollY: currentPos,
    });
  }

  renderGamesDropdown() {
    const settings = {
      dots: false,
      fade: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      // adaptiveHeight: true
    };

    const gameItems = [
      {
        name: 'Life Cycle',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        url: '/'
      }
    ]

    return (
      <Slider className="slider" {...settings}>
        <div className="game _life-cycle">
          <div className="info-wrapper">
            <img src={LifeCycleLogo} alt="life-cycle" />
            <h3 className="name">Life Cycle</h3>
            <div className="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </div>
            <Button>Buy Now</Button>
          </div>
          <div className="image-wrapper">
            <img className="character_1" src={Widowmaker} alt="Widowmaker" />
            <img className="character_2" src={Baptiste} alt="Baptiste" />
          </div>
        </div>
        <div className="game _roblox">
          <div className="info-wrapper">
            <img src={RobloxLogo} alt="life-cycle" />
            <h3 className="name">Roblox<br />Lalaland</h3>
            <div className="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </div>
            <Button>Play Now</Button>
          </div>
          <div className="image-wrapper">
            <img className="character" src={Character} alt="Character" />
          </div>
        </div>
        <div className="game _coming-soon">
          <div className="info-wrapper">
            <img src={ComingSoonLogo} alt="life-cycle" />
            <h3 className="name">Coming Soon</h3>
            <div className="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </div>
            <Button>Learn More</Button>
          </div>
          <div className="image-wrapper">
            <img className="character" src={Genji} alt="Widowmaker" />
          </div>
        </div>
      </Slider>
    );
  }

  render() {
    const {
      lang, location, pathName, showKickStarterLink
    } = this.props;
    const { navUp, liHover, headerBg, onComingSoon } = this.state;
    const langPrefix = prefix(lang);

    return (
      <header
        className={`main-header container ${
          navUp ? 'main-header--hidden' : 'main-header--visible'
        } ${headerBg ? 'main-header--bg' : ''}`}
      >
        <Link
          to={`${langPrefix}/`}
          className="main-header__logo-wrapper"
          onClick={(e) => {
            this.handleToggleClick(e);
          }}
        >
          <LogoInterStellar className="main-header__logo" />
          { onComingSoon && <span className="main-header__name">
            INTERSTELLAR INTERACTIVE
          </span> }
        </Link>

        {showKickStarterLink && pathName === '/' && <a className="kickstarter-link" href={'https://www.kickstarter.com/projects/interstellar-games/lifecycle-champions-arena'} target="_blank">LIFECYCLE - KICKSTARTER</a>}

        { !onComingSoon && (
        <div className={`main-menu ${!liHover && pathName === '/' ? 'no-active-item' : ''}`}>
          <div className="main-menu__list">
            <div className={`devider ${(pathName.includes('games')) ? 'r-active' : ''}`}><i></i></div>
            <div
              className={`main-menu__list__item ${liHover === 'games' ? 'active' : ''} ${
                pathName.includes('games') ? 'active' : ''
              } has-dropdown`}
              onMouseEnter={e => this.handleEnter(e)}
              onMouseLeave={e => this.handleLeave(e)}
            >
              <Link
                to={`${langPrefix}/games`}
                data-target="games"
                onClick={(e) => {
                  this.handleToggleClick(e);
                }}
              >
                Games
              </Link>
              <div className="dropdown" data-target="games">
                { liHover === 'games' && <div className="dropdown__content">
                  {this.renderGamesDropdown()}
                </div>}
              </div>
            </div>
            <div className={`devider ${(pathName.includes('games')) ? 'l-active' : ''} ${(pathName.includes('how-we-build')) ? 'r-active' : ''}`}><i></i></div>
            <div 
              className={`main-menu__list__item  ${liHover === 'how-we-build' ? 'active' : ''} ${
                pathName.includes('how-we-build') ? 'active' : ''
              }`}
            >
              <Link
                to={`/#how-we-build`}
                onMouseEnter={e => this.handleEnter(e)}
                onMouseLeave={e => this.handleLeave(e)}
                data-target="how-we-build"
                onClick={(e) => {
                  this.handleToggleClick(e);
                }}
              >
                How we build
              </Link>
            </div>
            <div className={`devider ${(pathName.includes('how-we-build')) ? 'l-active' : ''} ${(pathName.includes('shop')) ? 'r-active' : ''}`}><i></i></div>
            <div 
              className={`main-menu__list__item ${liHover === 'shop' ? 'active' : ''} ${
                pathName.includes('shop') ? 'active' : ''
              }`}
            >
              <Link
                to={`${langPrefix}/shop`}
                onMouseEnter={e => this.handleEnter(e)}
                onMouseLeave={e => this.handleLeave(e)}
                data-target="shop"
                onClick={(e) => {
                  this.handleToggleClick(e);
                }}
              >
                Shop
              </Link>
            </div>
            <div className={`devider ${(pathName.includes('shop')) ? 'l-active' : ''} ${(pathName.includes('news')) ? 'r-active' : ''}`}><i></i></div>
            <div 
              className={`main-menu__list__item  ${liHover === 'news' ? 'active' : ''} ${
                !liHover && pathName.includes('news') ? 'active' : ''
              }`}
            >
              <Link
                to={`${langPrefix}/news`}
                onMouseEnter={e => this.handleEnter(e)}
                onMouseLeave={e => this.handleLeave(e)}
                data-target="news"
                onClick={(e) => {
                  this.handleToggleClick(e);
                }}
              >
                News
              </Link>
            </div>
            <div className={`devider ${(pathName.includes('news')) ? 'l-active' : ''}`}><i></i></div>
          </div>
        </div>
        )}
      </header>
    );
  }
}

export default withI18n()(NavPanel);
