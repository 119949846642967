import React from "react";

const SvgComponent = props => (
  <svg
    width={24}
    height={20}
    viewBox="0 0 24 20"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>twitter</title>
    <g id="Page-1" fill="none" fillRule="evenodd">
      <g id="Artboard-1" fill="#FFF" transform="translate(-53 -98)">
        <path
          d="M74.533 103.112c.015.213.015.426.015.64 0 6.502-4.949 13.994-13.995 13.994A13.9 13.9 0 0 1 53 115.538c.396.046.777.061 1.188.061a9.85 9.85 0 0 0 6.106-2.102 4.927 4.927 0 0 1-4.599-3.41c.305.045.61.075.93.075.441 0 .883-.06 1.294-.167a4.92 4.92 0 0 1-3.944-4.827v-.061a4.954 4.954 0 0 0 2.223.624 4.915 4.915 0 0 1-2.193-4.096c0-.914.244-1.752.67-2.483a13.981 13.981 0 0 0 10.142 5.147 5.553 5.553 0 0 1-.122-1.126 4.917 4.917 0 0 1 4.92-4.92c1.415 0 2.695.595 3.593 1.554a9.684 9.684 0 0 0 3.122-1.188 4.905 4.905 0 0 1-2.163 2.711 9.86 9.86 0 0 0 2.833-.761 10.573 10.573 0 0 1-2.467 2.543z"
          id="path-1"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;

