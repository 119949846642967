import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Trans, withI18n } from '@lingui/react';
import NavPanel from './NavPanel';
import { Link } from 'gatsby';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import KickStarterBanner from '../components/KickStarterBanner';
import moment from 'moment';

// Images
import Facebook from './img/Facebook';
import Instagram from './img/Instagram';
import Twitter from './img/Twitter';
import Ttv from './img/Ttv';
import Youtube from './img/Youtube';
import ESRB from './img/ESRB';
import ESRB2 from './img/ESRB2';

// styles
import '../styles/style.scss';

class TemplateWrapper extends Component {
  constructor(props) {

    super(props);
    this._$refTemplateWrapper = null;
    this.state = {
      $refTemplateWrapper: null,
      decoratorCls: '',
      showComingSoon: true,
      showKickStarterBanner: false,
      showKickStarterOnNav: false,
      showOverlay: false,
      onDevelopment: null,
      funcDecorator: (cls) => {
        this.setState({
          decoratorCls: cls,
        });
      },
    };
  }
  
  componentDidMount() {
    const { location } = this.props;
    const onDevelopment = location.href.indexOf('staging') > -1 || location.href.indexOf('localhost') > -1;

    this.setState({
      onDevelopment: onDevelopment,
      $refTemplateWrapper: this._$refTemplateWrapper,
      showKickStarterBanner: false,
      showOverlay: !getCookieConsentValue('ii_session')
    });
  }

  onAcceptConsent() {
    this.setState({
      showKickStarterBanner: false,
      showOverlay: !getCookieConsentValue('ii_session')
    });
  }

  onKickStarterBannerClose() {
    this.setState({
      showKickStarterOnNav: false,
      showOverlay: false
    })
  }

  renderFooterV2() {
    return (
      <footer className="main-footer _v2">
        <div className="wrapper container">
          <div className="line _1"></div>
          <div className="line"></div>
          <div className="col-left">
              <ul className="links-wrapper">
                <li>
                  <label>Interstellar Interactive &copy; {moment().format('Y')} LLC. ALL RIGHTS RESERVED.</label>
                  <div>
                    <Link to={`/terms-and-conditions`}>TERMS & CONDITIONS</Link> |  <Link to="/privacy-policy">PRIVACY POLICY</Link>
                  </div>
                </li>
              </ul>
              <div className="esrb-wrapper">
              <ESRB2 />
              <ESRB />
            </div>
          </div>
        </div>
      </footer>
    );
  }

  render() {
    const { pathName, children, location } = this.props;
    const isLabPage = pathName.includes('lab') || pathName.includes('process');
    const lightCls = isLabPage ? 'page-is-light' : '';
    
    const {
      $refTemplateWrapper, funcDecorator, decoratorCls, showComingSoon, showKickStarterBanner, showKickStarterOnNav, showOverlay, onDevelopment
    } = this.state;

    const comingSoonCls = showComingSoon ? 'on-coming-soon' : '';
    const withOverlayCls = showOverlay ? 'with-overlay' : '';

    return (
      <div
        ref={(_mount) => {
          this._$refTemplateWrapper = _mount;
        }}
        className={`main-wrapper ${lightCls} ${decoratorCls} ${comingSoonCls} ${withOverlayCls}`}
        >
        <TemplateWrapperContext.Provider
          value={{ $refTemplateWrapper, funcDecorator }}
        >
          <Helmet defaultTitle="Interstellar Interactive" titleTemplate="%s - Announcement soon">
            <meta name="og:type" content="website" />
            <meta name="og:site_name" content="Interstellar Interactive" />
            <title>Interstellar Interactive</title>
            <meta name="description" content="We are Interstellar Interactive, a video game studio from Montreal, Canada. Our ambitions go beyond fundamentals and the space frontier is our trajectory." />
          </Helmet>
          
          <NavPanel
            {...this.props}
            onComingSoon={showComingSoon}
            showKickStarterLink={showKickStarterOnNav}
          />
          <main className="main-content">
            { children }
          </main>
          { showComingSoon ?
            this.renderFooterV2()
            :
            <footer className="main-footer">
              <div className="wrapper container">
                <div className="line _1"></div>
                <div className="line"></div>
                <div className="col-left">
                  <Trans>
                    <ul className="links-wrapper">
                      <li>
                        <label>CONTACT US</label>
                        <div>
                          <Link to="/">MEDIA INQUIRIES</Link> |  <Link to="/">INVESTORS</Link> |  <Link to="/">SUPPORT</Link>
                        </div>
                      </li>
                      <li>
                        <label>CAREERS</label>
                        <div>
                          <Link to="/">OUR CULTURE</Link> |  <Link to="/">SEND US YOUR RESUME</Link>
                        </div>
                      </li>
                      <li>
                        <label>Interstellar Interactive &copy; {moment().format('Y')} LLC. ALL RIGHTS RESERVED.</label>
                        <div>
                          <Link to="/">LEGAL INFO</Link> |  <Link to="/">COOKIES</Link> |  <Link to="/">TERMS & CONDITIONS</Link> |  <Link to="/">PRIVACY POLICY</Link>
                        </div>
                      </li>
                    </ul>
                  </Trans>
                </div>
                <div className="col-right">
                <select id="language-select" onChange={this.handleInputChange}>
                  <React.Fragment>
                    <option value="website">English</option>
                    <option value="webapp">French</option>
                  </React.Fragment>
                </select>
                  <div className="social-links">
                    <a className="social-links__item _twitter" href="https://twitter.com/InterstellarMTL" target="_blank"><Twitter /></a>
                    <a className="social-links__item _facebook" href="https://www.facebook.com/InterstellarInteractive" target="_blank"><Facebook /></a>
                    <a className="social-links__item _youtube" href="/" target="_blank"><Youtube /></a>
                    <a className="social-links__item _ttv" href="/" target="_blank"><Ttv /></a>
                    <a className="social-links__item _instagram" href="https://www.instagram.com/interstellarinteractive/" target="_blank"><Instagram /></a>
                  </div>

                  <div className="esrb-wrapper">
                    <ESRB2 />
                    <ESRB />
                  </div>
                </div>
              </div>
            </footer>
          }
          {(pathName === '/' && showKickStarterBanner) && <KickStarterBanner onClose={() => this.onKickStarterBannerClose()}/>}
          <CookieConsent
            containerClasses="cookie-consent"
            location="bottom"
            buttonText="Accept"
            cookieName="ii_session"
            disableStyles={true}
            disableButtonStyles={true}
            buttonClasses="button button--black"
            expires={9999}
            onAccept={() => this.onAcceptConsent()}
          >
            <div>
              This website uses cookies.<br />
              By using this website you consent to our use of these cookies.<br /><br />
              <span className="">For more information visit our { ' ' }
                <Link to="/privacy-policy">Privacy Policy</Link>.</span>
            </div>
          </CookieConsent>
        </TemplateWrapperContext.Provider>
      </div>
    );
  }
}

export default withI18n()(TemplateWrapper);
export const TemplateWrapperContext = React.createContext();
