import React, { Component } from 'react';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import TemplateWrapper from '../components/TemplateWrapper';
import {
  catalogs, langFromPath, languages, prefix, deprefix,
} from '../i18n-config';
import Transition from '../components/Transition';

i18n.load('en', catalogs.en.messages);
i18n.load('fr', catalogs.fr.messages);

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: langFromPath(props.location.pathname),
      prevElem: null
    };
    i18n.activate(langFromPath(props.location.pathname));
    // this.prevElem = null;
    // document.addEventListener('mousemove', this.onMouseMove)
    // // document.addEventListener('mouseleave', this.onMouseLeave)

    // setTimeout(() => {
    //   document.querySelector('.border-animation').onmousemove = (e) => {

    //     const x = e.pageX - e.target.offsetLeft
    //     const y = e.pageY - e.target.offsetTop
      
    //     e.target.style.setProperty('--x', `${ x }px`)
    //     e.target.style.setProperty('--y', `${ y }px`)
        
    //   }
    // }, 1000);
  }

  onMouseMove(event) {
    // const {prevElem} = this.state;

    const targetElem = event.target;
    // console.log(event);
    
    if (event.target.classList.contains('border-animation')) {
      const rect = targetElem.getBoundingClientRect();
      const width = targetElem.clientWidth;
      const height = targetElem.clientHeight;
      const posX = event.pageX - rect.x;
      const posY = event.pageY - rect.y;
      const mouseXpercentage = Math.round(posX / width * 100);
      const mouseYpercentage = Math.round(posY / height * 100);
      // this.setState({prevElem:targetElem});
      this.prevElem = targetElem;
      // targetElem.style.background = `radial-gradient(circle at ${mouseXpercentage}% ${mouseYpercentage}%, rgba(255,255,255,1), transparent 90%)`;
    
      event.target.style.setProperty('--x', `${ mouseXpercentage }px`)
      event.target.style.setProperty('--y', `${ mouseYpercentage }px`)
    
    } else {
      if (this.prevElem) {
        this.prevElem.style.background = null;
      }
    }
  }

  onMouseLeave(event) {
    if (event.target.classList.contains('border-animation')) {
      const targetElem = event.target;
      targetElem.style.background = null;
    }
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const lang = langFromPath(this.props.location.pathname);
      i18n.activate(lang);
    }
  }

  render() {
    const pathName = this.props.location.pathname;
    const lang = langFromPath(pathName);

    return (
      <I18nProvider i18n={i18n}>
        <TemplateWrapper key={this.state.lang} location={this.props.location} languages={languages} pathName={pathName} lang={lang}>
          <Transition location={this.props.location}>{this.props.children}</Transition>
        </TemplateWrapper>
      </I18nProvider>
    );
  }
}

export default Layout;
